/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import LoginForm from "components/LoginForm";
import { useErrorParams } from "helpers/use-error-params";

import styles from "./styles.scss";

const LoginView = () => {
  const { content: { loginview } } = useContext(StoreInfoContext);
  useErrorParams({ "bad_loginkey": "errorInvalidLogin" });

  return (
    <div className={cn(styles.block, "awardit-loginView")}>
      <Helmet title={loginview.pageTitle ?? ""} />
      <Wrapper className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.container__text}>
            <h2>{loginview.heading ?? ""}</h2>
            {/* eslint-disable react/no-danger */}
            {loginview.description &&
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: loginview.description }}
              />
            }
            {/* eslint-enable react/no-danger */}
          </div>
          <div className={styles.container__login}>
            <LoginForm className={styles.login_form} />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LoginView;
