/* @flow */

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { CurrentPageInfoData } from "data";
import { getCurrentPageInfo } from "state/current-page-info";
import { StoreInfoContext } from "entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import Wrapper from "components/Wrapper";
import Pixel from "components/Pixel";
import HeroIntro from "components/HeroIntro";
import { stutter } from "helpers/animation";
import { Title } from "components/UiComponents";

import styles from "./styles.scss";
import cn from "classnames";

const NUM_DUMMY_CARDS = 6;

export const DummyNews = () => {
  return (
    <div className={styles.dummy}>
      <div className={styles.top}>
        <Pixel className={styles.imagePixel} />
      </div>
      <div className={styles.bottom}>
        <p />
        <p />
        <p />
      </div>
    </div>
  );
};

const NewsListView = () => {
  const { content: { newslistview: content, heroIntroView } } = useContext(StoreInfoContext);
  const sendMessage = useSendMessage();
  const currentPageInfoData = useData(CurrentPageInfoData);

  const _stutter = stutter(100, 100);

  useEffect(() => {
    sendMessage(getCurrentPageInfo("NEWSVIEW"));
  }, []);

  const newsCurrentInfo = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.newsview &&
    currentPageInfoData.data.newsview.length > 0 ? currentPageInfoData.data.newsview : [];

  return (
    <div className={cn(styles.block, "awardit-newsListView")}>
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro
          title={content.heading ?? ""}
          description={content.description ?? ""}
        />
      }
      <Wrapper>
        <Helmet
          title={content.pageTitle ?? ""}
        />
        <div className={styles.innerBlock}>
          {Boolean(heroIntroView.toggle) === false &&
          <>
            {content.heading &&
              <Title>{content.heading}</Title>
            }
            {content.description &&
              <p>{content.description}</p>
            }
          </>
          }
          <div className={cn(styles.items, "awardit-newsListViewItems")}>
            {newsCurrentInfo
              .map((x, i) => (
                <div
                  key={x.title}
                  id={`newsItem-${x.id ? x.id : ""}`}
                  className={cn(styles.item, "awardit-newsListViewItem")}
                  style={{
                    animationDelay: _stutter(i),
                  }}
                >
                  <img className={styles.image} src={x.image} alt="" />
                  <div className={cn(styles.contentWrapper, "awardit-newsListItemContent")}>
                    <span className={styles.date}>{x.fromDate}</span>
                    <h2>{x.title}</h2>
                    {/* eslint-disable react/no-danger */}
                    <p
                      className="awardit-newsListItemText"
                      dangerouslySetInnerHTML={{ __html: x.content }}
                    />
                    {/* eslint-enable react/no-danger */}
                  </div>
                </div>
              ))
            }

            {currentPageInfoData.state === "LOADED" && currentPageInfoData.data.newsview && currentPageInfoData.data.newsview.length < 1 && content.noItemsText &&
            <div className={styles.no_news}>
              <h2>{content.noItemsText}</h2>
            </div>
            }

            {currentPageInfoData.state !== "LOADED" &&
          [...new Array(NUM_DUMMY_CARDS)]
            .map((x, i) => (
              <div
                key={"dummyNews_" + i}
                style={{
                  animationDelay: _stutter(i),
                }}
                className={styles.item}
              >
                <DummyNews />
              </div>
            ))
            }
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default NewsListView;
