/* @flow */

import React, { useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { TransactionsData } from "data";
import { SecondaryLogo } from "components/AppHeader/Logo";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import cn from "classnames";
import { HeadlessLastOrders } from "components/AccountView/last-orders";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  className: string,
};

const AppFooter = ({ className = "" }: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const {
    content: { appfooter, appfootertransactions },
    routes,
    configuration: { showCookieConsent, showPrivacyNotice },
  } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);
  const sendMessage = useSendMessage();
  const {
    footerText,
    copyrightText,
    leftColumnHeading,
    leftColumnText,
    leftColumnButtonText,
    leftColumnButtonLink,
    rightColumnHeading,
    rightColumnText,
    rightColumnButtonText,
    rightColumnButtonLink,
    transactionsHeading,
    transactionsText,
    contactHeading,
    contactColumn,
    contactButtonLink,
    disableDividers,
  } = appfooter;
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  const termsLink = routes.termsView && routes.termsView.toggle !== undefined &&
  routes.termsView.toggle && routes.termsView.url !== undefined &&
  routes.termsView.url && routes.termsView.title !== undefined && routes.termsView.title ?
    {
      title: routes.termsView.title,
      url: routes.termsView.url,
    } : null;

  const displayTransactions = () => {
    let res = false;
    const home = appfootertransactions.home !== undefined &&
      appfootertransactions.home !== null &&
      appfootertransactions.home === true;
    const other = appfootertransactions.other !== undefined &&
      appfootertransactions.other !== null &&
      appfootertransactions.other === true;

    if (home && (location.pathname === "/" || location.pathname.includes("index.html"))) {
      res = true;
      return res;
    }

    if (other) {
      res = true;
    }

    for (const [key, value] of Object.entries(appfootertransactions)) {
      const exists = value !== undefined &&
        value !== null &&
        value === true;

      if (location.pathname.split("/")[1] === key && exists) {
        res = value;
        return res;
      }
    }

    return res;
  };

  return (
    <footer className={cn(styles.block, className, "awardit-appFooter")}>
      <div className={styles.large}>

        {(leftColumnHeading || rightColumnHeading || contactHeading) &&
          <Wrapper className={styles.wrapper}>
            <div className={styles.section}>
              {leftColumnHeading &&
                <div className={styles.sectionDiv}>
                  <h3 className={styles.title}>{leftColumnHeading}</h3>
                  {/* eslint-disable react/no-danger */}
                  {leftColumnText &&
                    <div
                      className={styles.leftColumnText}
                      dangerouslySetInnerHTML={{ __html: leftColumnText }} />
                  }
                  {/* eslint-enable react/no-danger */}
                  {leftColumnButtonLink && leftColumnButtonText &&
                    <Button className={styles.footerLink} to={leftColumnButtonLink}>
                      {leftColumnButtonText}
                    </Button>
                  }
                </div>
              }

              {rightColumnHeading &&
                <div className={styles.sectionDiv}>
                  <h3 className={styles.title}>{rightColumnHeading}</h3>
                  {/* eslint-disable react/no-danger */}
                  {rightColumnText &&
                    <div
                      className={styles.leftColumnText}
                      dangerouslySetInnerHTML={{ __html: rightColumnText }} />
                  }
                  {/* eslint-enable react/no-danger */}
                  {rightColumnButtonLink && rightColumnButtonText &&
                    <Button className={styles.footerLink} to={rightColumnButtonLink}>
                      {rightColumnButtonText}
                    </Button>
                  }
                </div>
              }

              {contactHeading &&
                <div className={styles.sectionDiv}>
                  <h3 className={styles.title}>{contactHeading}</h3>
                  <div className={styles.contactLinks}>

                    {/* eslint-disable react/no-danger */}
                    <div
                      className={styles.contactColumnText}
                      dangerouslySetInnerHTML={{ __html: contactColumn }} />
                    {/* eslint-enable react/no-danger */}

                    {contactButtonLink &&
                      <Button className={styles.footerLink} to={contactButtonLink}>
                        {t("FOOTER.LINKS.CONTACT")}
                      </Button>
                    }
                  </div>
                </div>
              }
              <div className={cn(styles.sectionDiv, styles.logoWrapper)}>
                <SecondaryLogo width="290px" />
              </div>
            </div>
          </Wrapper>
        }

        {displayTransactions() && transaction.state === "LOADED" && transaction.data.length > 0 ? (
          <>
            {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
              <div className={cn("awardit-appFooterDivider", styles.divider)} />
            }

            <Wrapper className={styles.wrapper}>
              <div className={styles.transaction}>
                <div className={styles.left}>
                  {transactionsHeading && (
                    <>
                      <h2 className={styles.title}>{transactionsHeading}</h2>
                      {transactionsText &&
                        <p className={styles.description}>
                          {transactionsText}
                        </p>
                      }
                      {routes.accountView && routes.accountView.url &&
                        <Button to={routes.accountView.url} variant="primary">
                          {t("FOOTER.LINKS.GO_TO_MY_ACCOUNT")}
                        </Button>
                      }
                    </>
                  )}
                </div>
                {routes.accountTransactionsView && routes.accountTransactionsView.url &&
                  <div className={styles.right}>
                    <Link className={styles.link} to={routes.accountTransactionsView.url}>
                      <HeadlessLastOrders />
                    </Link>
                    <div className={styles.section__bottom}>
                      <Button
                        className={cn(styles.link, styles.centeredCta)}
                        to={routes.accountTransactionsView?.url}
                      >
                        {t("FOOTER.LINKS.ALL_TRANSACTIONS")}
                      </Button>
                    </div>
                  </div>
                }
              </div>
            </Wrapper>
          </>
        ) : null }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        {consentActivated && Boolean(showPrivacyNotice) &&
          <Wrapper className={cn("awardit-appFooterWrapper", styles.wrapper)}>
            <div className={styles.row}>
              <section className={styles.handleCookies}>
                <Button
                  className={styles.link}
                  onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                >
                  {t("FOOTER.NEED_HELP.COOKIES")}
                </Button>
                <Button
                  className={cn("awardit-appFooterPrivacyNoticeButton", styles.link)}
                  to="/privacy-notice"
                >
                  {t("FOOTER.LEGAL.PRIVACY_NOTICE")}
                </Button>
              </section>
            </div>
          </Wrapper>
        }

        {footerText && termsLink &&
        <>
          {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
            <div className={cn("awardit-appFooterDivider", styles.divider)} />
          }

          <Wrapper className={cn("awardit-appFooterWrapper", styles.wrapper)}>
            <div className={cn(styles.row, styles.additionalHtml)}>
              <section className={styles.content}>
                <p>
                  {footerText}
                </p>
                {termsLink &&
                  <Button
                    className={styles.link}
                    to={termsLink.url}
                  >
                    {termsLink.title}
                  </Button>
                }
              </section>
            </div>
          </Wrapper>
        </>
        }

        {appfooter.additionalHtml &&
        <>
          {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
            <div className={cn("awardit-appFooterDivider", styles.divider)} />
          }

          <Wrapper className={cn("awardit-appFooterWrapper", styles.wrapper)}>
            <section className={styles.copyright}>
              {/* eslint-disable react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: appfooter.additionalHtml }} />
              {/* eslint-enable react/no-danger */}
            </section>
          </Wrapper>
        </>
        }

        {copyrightText &&
          <>
            {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
              <div className={cn("awardit-appFooterDivider", styles.divider)} />
            }

            <Wrapper className={cn("awardit-appFooterWrapper", styles.wrapper, styles.copyrightWrapper)}>
              <section className={styles.copyright}>
                <div className={styles.row}>
                  <span>{copyrightText}</span>
                </div>
              </section>
            </Wrapper>
          </>
        }
      </div>
    </footer>
  );
};

export default AppFooter;
