/* @flow */

import type { Mode } from "state/view-mode";
import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { Menu } from "@crossroads/ui-components";
import { logout } from "@crossroads/shop-state/customer";
import { StoreInfoContext } from "entrypoint/shared";
import { useSendMessage } from "crustate/react";
import cn from "classnames";
import useFormat from "helpers/use-format";
import SystemMessages from "components/SystemMessages";
import Logo from "components/AppHeader/Logo";
import Button from "components/Button";
import CustomerBadge from "components/CustomerBadge";
import { MODE } from "state/view-mode";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import SubMenu from "components/AppHeader/Large/SubMenu";
import HamburgerIcon from "icons/hamburger.svg";
import AdminButton from "components/AccountView/AdminButton";

import styles from "./styles.scss";

type Props = {
  className: string,
  mode: Mode,
  setMode: Mode => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  isScrolled: boolean,
  lastVisited: ?string,
  customer: ?Customer,
  logoWidth: string,
  adminUser?: boolean,
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long", day: "numeric" });
};

const noDimModes = [MODE.NORMAL, MODE.RECRUIT, MODE.TINK];

const AppHeaderLarge = ({
  className = "",
  mode,
  setMode,
  onHomeView,
  onCheckout,
  lastVisited,
  customer,
  logoWidth,
  isScrolled,
  onSuccess,
  adminUser,
}: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const storeInfo = useContext(StoreInfoContext);
  const { routes, info } = storeInfo;
  const { content: { accountview, appheader, tinkview } } = storeInfo;
  const { formatPoints } = useFormat();
  const showAdminButton = Boolean(accountview.showAdministration) &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;
  const compactMenu = appheader.compactMenu !== undefined &&
    appheader.compactMenu !== null &&
    appheader.compactMenu === true;

  let UPDATEDROUTES = null;

  if (tinkview.hideInNavbar !== undefined &&
    tinkview.hideInNavbar !== null &&
    tinkview.hideInNavbar === true) {
    UPDATEDROUTES = ROUTES.filter(item => item !== "accountTinkView");
  }

  const nav = navRoutes(UPDATEDROUTES ? UPDATEDROUTES : ROUTES, storeInfo);

  const expiringPoints = customer && customer.awardit.expiringPoints > 0 ?
    formatPoints(customer.awardit.expiringPoints) : null;
  const expiringPointsDate = customer && customer.awardit.expiringPointsDate ?
    formatDate(customer.awardit.expiringPointsDate) : null;

  return (
    <div className={styles.container}>
      <div className={cn(
        styles.block,
        className,
        { [styles.fullMenu]: onHomeView },
        { [styles.onHomeView]: onHomeView })}
      >
        {routes.homeView && routes.homeView.url &&
          <Link className={cn(styles.item, styles.logo)} to={routes.homeView?.url}>
            <Logo
              width={logoWidth}
              isInverted={!isScrolled}
              onClick={() => setMode(MODE.NORMAL)}
            />
          </Link>
        }

        <nav className={cn(styles.nav, "awardit-customerBadge")}>
          {showAdminButton &&
            <div className={cn(styles.adminBtnContainer, "awardit-adminButton")}>
              {accountview.administration &&
                <AdminButton text={accountview.administration} />
              }
            </div>
          }
          {routes.accountView && routes.accountView.toggle !== undefined &&
          routes.accountView.toggle && (
            <NavLink
              to={routes.accountView.url}
              activeClassName={styles.active}
              className={cn(styles.item, styles.item__customerBadge)}
            >
              <CustomerBadge customer={customer} />
            </NavLink>
          )}
          {accountview.logout &&
            <Button className={styles.logout} variant="link" onClick={() => sendMessage(logout())}>
              {accountview.logout}
            </Button>
          }
        </nav>
      </div>

      <nav className={cn(styles.nav, styles.linksNav, "awardit-appHeaderLinks")}>
        {!onCheckout && info.code === "releasefinans_sek" &&
          <NavLink
            key="about-release"
            to="about-release"
            activeClassName={styles.active}
            className={styles.item}
          >
            Om Release Points
          </NavLink>
        }
        {!onCheckout && info.code === "postkodlotteriet_sek" &&
          <NavLink
            key="about-wownyckeln"
            to="about-wownyckeln"
            activeClassName={styles.active}
            className={styles.item}
          >
            Om Wownyckeln
          </NavLink>
        }
        {routes.teamPot &&
        routes.teamPot.toggle !== undefined &&
        routes.teamPot.toggle &&
        typeof routes.teamPot.url === "string" &&
        routes.teamPot.url.length > 0 &&
        adminUser === true &&
          <NavLink
            to={routes.teamPot.url}
            activeClassName={styles.active}
            className={styles.item}
          >
            {routes.teamPot.title}
          </NavLink>
        }

        {!onCheckout && info.code === "tele2_sek" &&
          <div
            className={cn(styles.navDropdown, styles.item, "awardit-appHeaderLinksTele2")}
          >
            <span>Dokumentbibliotek</span>
            <section className={styles.navDropdownLinks}>

              {routes.alternateContent1 &&
              routes.alternateContent1.title &&
                <NavLink
                  to={routes.alternateContent1.url}
                  activeClassName={styles.active}
                  className={styles.item}
                >
                  {routes.alternateContent1.title}
                </NavLink>
              }
              {routes.alternateContent2 &&
              routes.alternateContent2.title &&
                <NavLink
                  to={routes.alternateContent2.url}
                  activeClassName={styles.active}
                  className={styles.item}
                >
                  {routes.alternateContent2.title}
                </NavLink>
              }
              {routes.alternateContent3 &&
              routes.alternateContent3.title &&
                <NavLink
                  to={routes.alternateContent3.url}
                  activeClassName={styles.active}
                  className={styles.item}
                >
                  {routes.alternateContent3.title}
                </NavLink>
              }
            </section>
          </div>
        }
        {!onCheckout && info.code === "dreamteam_sek" &&
          <NavLink
            key="about-dreamteam"
            to="about-dreamteam"
            activeClassName={styles.active}
            className={styles.item}
          >
            Tjäna poäng
          </NavLink>
        }
        {!onCheckout && !compactMenu && nav.map(x => (
          <NavLink
            key={x.url}
            to={x.url}
            activeClassName={styles.active}
            className={styles.item}
          >
            {x.title}
          </NavLink>
        ))}

        {!onCheckout && !compactMenu && routes.suppliersView &&
        routes.suppliersView.toggle !== undefined && routes.suppliersView.toggle && (
          <NavLink
            key={routes.suppliersView.url}
            to={routes.suppliersView.url}
            activeClassName={styles.active}
            className={styles.item}
          >
            {routes.suppliersView.title}
          </NavLink>
        )}

        {compactMenu &&
          <Menu className={styles.item} icon={<HamburgerIcon />}>
            {!onCheckout && nav.map(x => (
              <Link key={x.url} to={x.url} className={styles.menuItem}>
                {x.title}
              </Link>
            ))}
            {!onCheckout && routes.suppliersView && routes.suppliersView.toggle !== undefined &&
            routes.suppliersView.toggle && (
              <Link to={routes.suppliersView.url} className={styles.menuItem}>
                {routes.suppliersView.title}
              </Link>
            )}
          </Menu>
        }

        {expiringPointsDate && expiringPoints &&
        routes.accountView && routes.accountView.url &&
          <NavLink
            to={routes.accountView.url}
            className={cn(styles.item, styles.expiresNotice)}
          >
            <div className={styles.exclamation}>!</div>
            <div className={styles.notice}>
              <span className={styles.date}>
                {`${t("ACCOUNT.EXPIRES")} ${expiringPointsDate}`}
              </span>
              <span className={styles.points}>
                {expiringPoints}
              </span>
            </div>
          </NavLink>
        }
      </nav>

      <SubMenu
        mode={mode}
        setMode={setMode}
        lastVisited={lastVisited}
        onCheckout={onCheckout}
        onSuccess={onSuccess} />

      {!noDimModes.includes(mode) &&
        <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
      }

      <div>
        <SystemMessages />
      </div>
    </div>
  );
};

export default AppHeaderLarge;
