/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  className?: string,
  height?: string,
  width: string,
  isInverted?: boolean,
  loginViewLogo?: string,
};

export const logoSvg = (logo?: string, width: string, height: string) => {
  if (!logo) {
    return null;
  }

  return (
    <img src={logo} width={width} height={height} className={cn("awardit-Logo")} />
  );
};

export const SecondaryLogo = ({ width = "50px", height = "50px", className, ...props }: Props) => {
  const {
    configuration: { logoSecondary },
  } = useContext(StoreInfoContext);

  return (
    <div {...props} className={cn(styles.block, className)}>
      {logoSecondary && logoSvg(logoSecondary, width, height) }
    </div>
  );
};

const Logo = ({ width = "50px", height = "50px", isInverted, loginViewLogo, className, ...props }: Props) => {
  const {
    info: { name },
    configuration: { logo, logoInverted },
  } = useContext(StoreInfoContext);

  const logoType = loginViewLogo ? loginViewLogo : logo;

  return (
    <div {...props} className={cn(styles.block, styles.maxHeight, className)}>
      {logoInverted && isInverted !== undefined && isInverted ?
        logoSvg(logoInverted, width, height) :
        logoSvg(logoType, width, height) }
      {!logo && name &&
        <span className={styles.name}>
          {name}
        </span>
      }
    </div>
  );
};

export default Logo;
