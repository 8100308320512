/* @flow */

import React, { useContext, useState, useEffect } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";

type Value = string | number;
type LeaderboardItems = $ReadOnlyArray<LeaderboardItems>;

import { StoreInfoContext } from "entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import { getLoadLeaderboardByDate } from "state/leaderboard";
import Button from "components/Button";
import HeroLeaderboardTable from "components/HomeHeroLeaderboard/HeroLeaderboardTable";
import UserIcon from "icons/user-leaderboard.svg";
import ArrowUpIcon from "icons/arrow-up-leaderboard.svg";
import StarIcon from "icons/star-leaderboard.svg";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";

import SortOptions from "components/LeaderboardView/SortOptions";
import { LeaderboardData, CustomerData, TransactionsData, LeaderboardDistrictsData } from "data";

import styles from "./styles.scss";
import TransactionTable from "components/TransactionTable";

const HomeHero = () => {
  const sendMessage = useSendMessage();
  const { info, routes, content: { leaderboardview, homeview } } = useContext(StoreInfoContext);
  const t = useTranslate();
  const customer = useData(CustomerData);
  const transaction = useData(TransactionsData);
  const leaderboard = useData(LeaderboardData);
  const leaderboardDistrics = useData(LeaderboardDistrictsData);
  const leaderboardDistricsList = leaderboardDistrics.state === "LOADED" ? leaderboardDistrics.data : [];
  const leaderboardRows = leaderboard.state === "LOADED" ? leaderboard.data.leaderboard.slice(0, 3) : [];
  const leaderboardCount = leaderboard.state === "LOADED" && leaderboard.data.amountofwinners ? leaderboard.data.amountofwinners.count : 0;
  const currentLeaderboardUser = leaderboard.state === "LOADED" && leaderboard.data.user ?
    leaderboard.data.user : null;
  const currentLeaderboardTabelUser = leaderboard.state === "LOADED" ?
    leaderboard.data.leaderboard.find(item => item.name === leaderboard.data.user.name) :
    null;
  const leaderboardCurrentDistric = leaderboard.state === "LOADED" && leaderboard.data.currentdistrict ?
    leaderboard.data.currentdistrict : null;
  const customerExtraData = customer.state === "LOGGED_IN" &&
    customer.data.awarditCustomerExtra !== null ?
    customer.data.awarditCustomerExtra.split(";") : null;

  const initDate = new Date();

  const [currentDistrict, setCurrentDistrict] = useState<string | null>(leaderboardCurrentDistric);
  const [activeLeaderboard, setActiveLeaderboard] = useState<string>("year");
  const [selectedFromDate, setSelectedFromDate] = useState<string>("");
  const [selectedToDate, setSelectedToDate] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<Value | null>(initDate.getFullYear().toString());
  const [pointsDeff, setPointsDeff] = useState<number | null>(null);

  const lastDateOfMonth = (date: Date) => (
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  );

  const previousMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    let dateArr = [];

    if (month === 0) {
      dateArr = [year - 1, 11];
    }
    else {
      dateArr = [year, month - 1];
    }

    if (dateArr[1] < 9) {
      return `${dateArr[0]}-0${dateArr[1] + 1}`;
    }

    return `${dateArr[0]}-${dateArr[1] + 1}`;
  };

  const getMonthLeaderboard = () => {
    const date = new Date();
    const month = JSON.stringify(date.getMonth() + 1);
    const year = date.getFullYear();

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${year}-${month.length > 1 ? month : "0" + month}-01`,
      todate: `${year}-${month.length > 1 ? month : "0" + month}-${lastDateOfMonth(date)}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("month");
  };

  const getPrevMonthLeaderboard = () => {
    const currentDate = new Date();
    const prevYearMonth = previousMonth(currentDate);
    const prevDate = new Date(prevYearMonth);

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${prevYearMonth}-01`,
      todate: `${prevYearMonth}-${lastDateOfMonth(prevDate)}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("prevMonth");
  };

  const getYearLeaderboard = () => {
    const date = new Date();
    const fromDate = `${date.getFullYear()}-01-01`;
    const toDate = `${date.getFullYear()}-12-31`;

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("year");
  };

  const getSelectedYearLeaderboard = (selectedYear: Value) => {
    const date = new Date(selectedYear);
    const fromDate = `${date.getFullYear()}-01-01`;
    const toDate = `${date.getFullYear()}-12-31`;

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
  };

  const getCustomDateLeaderboard = (fromDate: string, toDate: string) => {
    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
  };

  useEffect(() => {
    if (leaderboard.state === "LOADED" && currentLeaderboardUser) {
      if (leaderboardCount > 0 && leaderboardRows.length > 0) {
        setPointsDeff(leaderboardRows[leaderboardCount - 1].points -
          (currentLeaderboardUser.points !== null ? currentLeaderboardUser.points : 0));
      }
      else if (leaderboardRows.length > 0) {
        setPointsDeff(leaderboardRows[0].points -
          (currentLeaderboardUser.points !== null ? currentLeaderboardUser.points : 0));
      }
      else {
        setPointsDeff(0);
      }
    }
  }, [leaderboard]);

  useEffect(() => {
    if (leaderboard.state !== "LOADED") {
      return;
    }

    if (currentDistrict) {
      const { flags } = leaderboard.data;
      if (flags && (flags.showdatesel > 0 && flags.showdatesel < 3)) {
        getYearLeaderboard();

        return;
      }

      sendMessage(getLoadLeaderboardByDate({ district: currentDistrict }));
    }
  }, [currentDistrict]);

  useEffect(() => {
    if (currentDistrict === null && leaderboardDistricsList.length > 0) {
      const arr = leaderboardDistricsList.filter(district => district.primary === true);
      if (arr.length !== 0) {
        const primaryDist = arr[0].id;
        setCurrentDistrict(primaryDist);
      }
    }
  }, [leaderboardDistricsList]);

  const renderMissingInfoMessage = () => {
    const lbData = leaderboard.state === "LOADED" ? leaderboard.data : null;
    const excludedPartners = ["if_sek"];

    if (lbData === null || excludedPartners.includes(info.code)) {
      return null;
    }

    if (homeview.motivationLeaderboardWidgetIsActiveNameTable !== undefined &&
      homeview.motivationLeaderboardWidgetIsActiveNameTable !== null &&
      homeview.motivationLeaderboardWidgetIsActiveNameTable === false &&
      lbData.user.points === 0 && lbData.user.position === 0) {
      return (
        <div className={cn(styles.widgetRow, styles.missingData)}>
          <div className={styles.missingDataInner}>
            <span>{t("LEADERBOARD.MISSING_USER_DATA")}</span>
          </div>
        </div>
      );
    }

    if ((leaderboard.state === "LOADED" && lbData.leaderboard.length === 0)) {
      return (
        <div className={cn(styles.widgetRow, styles.missingData)}>
          <div className={styles.missingDataInner}>
            <span>{t("LEADERBOARD.MISSING_DATA")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={cn("awardit-homeHeroLeaderboardFullWidth", { [styles.fullWidth]: homeview.motivationHeroImageFullWidth })}
    >
      <div className={cn("awardit-homeHeroLeaderboardHero", styles.hero)}>
        <picture className={styles.image}>

          <source
            srcSet={homeview.motivationHeroImage ?? ""}
            media={`(max-width: ${parseInt(styles.tiny, 10) - 1}px)`}
          />
          <source
            srcSet={homeview.motivationHeroImage ?? ""}
            media={`(min-width: ${parseInt(styles.tiny, 10)}px)`}
          />
          <img
            alt=""
            src={homeview.motivationHeroImage ?? ""}
          />
        </picture>
        <div className={cn(styles.widgetWrapper, "awardit-leaderboardWidgetWrapper")}>
          {leaderboard.state === "LOADED" ?
            <section className={cn(styles.widget, "awardit-leaderboardWidget")}>
              {homeview.motivationHeroTitle &&
                <div className={styles.widgetHead}>
                  <h2>{homeview.motivationHeroTitle}</h2>
                </div>
              }
              <div className={styles.widgetControls}>
                {leaderboard.state === "LOADED" &&
                leaderboard.data.currentdistrict &&
                leaderboardDistricsList.length > 0 &&
                <div className={styles.districtDropdownContainer}>
                  <Dropdown
                    name="leaderboardDistricts"
                    className={cn(styles.dropdown, "awardit-leaderboardPageDistrictSelect")}
                    placeholder={leaderboardview.showAmoutOfColumns ?? ""}
                    value={currentDistrict ? currentDistrict : leaderboard.data.currentdistrict}
                    onChange={(val: any) => setCurrentDistrict(val)}
                  >
                    {leaderboardDistricsList.map(district => (
                      <DropdownItem
                        key={district.id}
                        value={district.id}
                      >
                        {district.label}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </div>
                }
                {leaderboard.state === "LOADED" && leaderboard.data.flags && leaderboard.data.flags.showdatesel > 0 &&
                <div className={styles.widgetCtas}>
                  <SortOptions
                    datesel={leaderboard.data.flags.showdatesel}
                    activeLeaderboard={activeLeaderboard}
                    getMonthLeaderboard={() => getMonthLeaderboard()}
                    getPrevMonthLeaderboard={() => getPrevMonthLeaderboard()}
                    getYearLeaderboard={() => getYearLeaderboard()}
                    getCustomDateLeaderboard={(from, to) => getCustomDateLeaderboard(from, to)}
                    getSelectedYearLeaderboard={
                      selectedYear => getSelectedYearLeaderboard(selectedYear)
                    }
                    selectedFromDate={selectedFromDate}
                    selectedToDate={selectedToDate}
                    selectedYear={selectedYear}
                    setSelectedFromDate={x => setSelectedFromDate(x)}
                    setSelectedToDate={x => setSelectedToDate(x)}
                    setSelectedYear={x => setSelectedYear(x)}
                  />
                </div>
                }
              </div>
              {homeview.motivationHeroDescription &&
                /* eslint-disable react/no-danger */
                <div
                  dangerouslySetInnerHTML={{ __html: homeview.motivationHeroDescription }}
                  className={styles.widgetHead}
                />
                /* eslint-enable react/no-danger */
              }

              {renderMissingInfoMessage()}

              {homeview.motivationHeroPointsText && leaderboard.data.user.points !== null &&
              currentLeaderboardUser && (
                <div className={styles.widgetRow}>
                  <StarIcon />
                  <p>{homeview.motivationHeroPointsText}</p>
                  <p>{currentLeaderboardUser.points}</p>
                </div>
              )}
              {homeview.motivationHeroPositionText && leaderboard.data.user.position !== null &&
              currentLeaderboardUser && (
                <div className={styles.widgetRow}>
                  <UserIcon />
                  <p>{homeview.motivationHeroPositionText}</p>
                  <p>{currentLeaderboardUser.position}</p>
                </div>
              )}
              {homeview.motivationHeroPointsToFirstText && leaderboard.data.user.points !== null &&
              currentLeaderboardUser && (
                <div className={styles.widgetRow}>
                  <ArrowUpIcon />
                  <p>{homeview.motivationHeroPointsToFirstText}</p>
                  <p>{pointsDeff !== null && pointsDeff}</p>
                </div>
              )}
              {(info.code === "if_sek" && customer.state === "LOGGED_IN" &&
                customerExtraData !== null && customerExtraData.length > 1) && (
                <>
                  <div className={styles.widgetRow}>
                    <UserIcon />
                    <p>Er försäkringsgrad</p>
                    <p>{customerExtraData[0]}</p>
                  </div>
                  <div className={styles.widgetRow}>
                    <StarIcon />
                    <p>Ditt senaste utfall mot index</p>
                    <p>{customerExtraData[1]}</p>
                  </div>
                </>
              )}
              {homeview.motivationLeaderboardWidgetIsActiveNameTable &&
                <HeroLeaderboardTable
                  currentLeaderboardUser={currentLeaderboardTabelUser}
                  leaderboardRows={leaderboardRows}
                />
              }
              {homeview.motivationDescription && (
                <div className={styles.motivationDescription}>
                  <p>{homeview.motivationDescription}</p>
                </div>
              )}
              {(homeview.motivationHeroButtonPrimaryText &&
              homeview.motivationHeroButtonPrimaryLink) ||
              (homeview.motivationHeroButtonSecondaryText &&
              homeview.motivationHeroButtonSecondaryLink) ?
                <div className={styles.cta}>
                  {homeview.motivationHeroButtonPrimaryText &&
                  homeview.motivationHeroButtonPrimaryLink &&
                  <Button to={homeview.motivationHeroButtonPrimaryLink} type="button" variant="primary">
                    {homeview.motivationHeroButtonPrimaryText}
                  </Button>
                  }
                  {homeview.motivationHeroButtonSecondaryText &&
                  homeview.motivationHeroButtonSecondaryLink &&
                  <Button to={homeview.motivationHeroButtonSecondaryLink} type="button" variant="ghost">
                    {homeview.motivationHeroButtonSecondaryText}
                  </Button>
                  }
                </div> : null}
            </section> :
            <section className={styles.widget}>
              <div className={styles.dummy}>
                <p className={styles.title}>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
            </section>
          }
          <section className={cn(styles.widget, styles.widgetRight, "awardit-leaderboardHeroRight")}>
            {homeview.heroPrimaryInfoFieldStatus !== undefined &&
            homeview.heroPrimaryInfoFieldStatus !== null &&
            homeview.heroPrimaryInfoFieldStatus === true && (
              <div className={cn(styles.top, "awardit-leaderboardHeroRightTop")}>
                <div className={styles.top__container}>
                  {homeview.heroPrimaryInfoFieldImage &&
                    <div className={cn(styles.imgContainer, "awaridit-leaderboardHeroRightImgContainer")}>
                      <img src={homeview.heroPrimaryInfoFieldImage} />
                    </div>
                  }
                  <div className={styles.content}>
                    {homeview.heroPrimaryInfoFieldHeading && (
                      <h3>{homeview.heroPrimaryInfoFieldHeading}</h3>
                    )}
                    {/* eslint-disable react/no-danger */}
                    {homeview.heroPrimaryInfoFieldText && (
                      <div
                        dangerouslySetInnerHTML={{ __html: homeview.heroPrimaryInfoFieldText }}
                      />
                    )}
                    {/* eslint-enable react/no-danger */}
                    {(homeview.heroPrimaryInfoFieldLink &&
                      homeview.heroPrimaryInfoFieldLinkText) && (
                      <div className={styles.currentInfoCardLinkContainer}>
                        <Button to={homeview.heroPrimaryInfoFieldLink}>
                          {homeview.heroPrimaryInfoFieldLinkText}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {homeview.motivationHeroTransactionStatus !== undefined &&
            homeview.motivationHeroTransactionStatus !== null &&
            homeview.motivationHeroTransactionStatus === true &&
            transaction.state === "LOADED" &&
            transaction.data.length > 0 && (
              <div className={cn(styles.bottom, "awaridit-leaderboardHeroBottom")}>
                <TransactionTable />
                {routes.accountTransactionsView && routes.accountTransactionsView.url &&
                  <div className={styles.section__bottom}>
                    <Button
                      className={cn(styles.link, styles.centeredCta)}
                      to={routes.accountTransactionsView?.url}
                    >
                      {t("FOOTER.LINKS.ALL_TRANSACTIONS")}
                    </Button>
                  </div>
                }
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
